@use 'styles/variables' as var;
@use 'styles/functions' as fn;
@use 'styles/backgrounds' as bg;
@use 'styles/images' as img;

.achieve-section {
  position: relative;

  &[data-default-pad-y='true'] {
    padding-top: fn.spacing(6);
    padding-bottom: fn.spacing(6);
  }

  &[data-variant='blueGradient1'] {
    background: bg.$bg-gradient-blue-1;
  }

  &[data-variant='purpleBlueGradient1'] {
    background: bg.$bg-gradient-purple-blue-1;
  }

  &[data-variant='neutralGrey8'] {
    background: var.$color-neutral-25;
  }

  &[data-variant='achieveHighlightBlue'] {
    background: var.$color-blue-50;
  }

  &[data-variant='blue25'] {
    background: var.$color-blue-25;
  }

  &[data-variant='blue'] {
    background: var.$color-blue-500;
  }

  &[data-variant='blue100'] {
    background: var.$color-blue-100;
  }

  &[data-variant='bgPrimaryHighlightBlue'] {
    background: var.$color-blue-50;
  }

  &[data-variant='white'] {
    background: var.$color-neutral-0;
  }

  &[data-variant='bgPrimaryHighlightBlue2'] {
    background: var.$color-blue-25;
  }

  &[data-variant='bgShadowPrimaryHighlightBlue'] {
    background: var.$color-blue-50;

    &::before {
      content: '';
      position: absolute;
      left: -21.18%;
      right: 88.15%;
      top: -26.6%;
      bottom: 31%;
      background: linear-gradient(180deg, rgb(25 56 219 / 20%) 24.21%, rgb(211 86 111 / 20%) 100%);
      filter: blur(75px);
      transform: rotate(-7.42deg);
    }
  }

  &[data-variant='bgShadowPrimaryHighlightWhiteBlue'] {
    background: #f8f9ff;

    &::before {
      content: '';
      position: absolute;
      left: 45.19%;
      right: 11.45%;
      top: 9.49%;
      bottom: 1.81%;
      background: linear-gradient(
        180deg,
        rgb(25 56 219 / 20%) 14.68%,
        rgb(33 161 255 / 20%) 73.99%,
        rgb(254 206 47 / 20%) 98.44%
      );
      filter: blur(76.3648px);
      transform: rotate(-7.42deg);
    }
  }
}

.achieve-pattern-container {
  @include img.img-span;
}

.achieve-pattern {
  opacity: 0.05;
  scale: 1.2;
}

.achieve-section-bg-img {
  display: none;
  position: absolute;
  background-size: cover;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.achieve-section-bg-img-sm {
  display: block;
}

@media only screen and (min-width: var.$breakpoint-md) {
  .achieve-section-bg-img-sm,
  .achieve-section-bg-img-lg {
    display: none;
  }

  .achieve-section-bg-img-md {
    display: block;
  }
}

@media only screen and (min-width: var.$breakpoint-lg) {
  .achieve-section-bg-img-md {
    display: none;
  }

  .achieve-section-bg-img-lg {
    display: block;
  }

  .achieve-section[data-default-pad-y='true'] {
    padding-top: fn.spacing(8);
    padding-bottom: fn.spacing(8);
  }
}
